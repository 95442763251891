import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Filter } from "@sharedV11/classes/filters/filter";
import { clientes_select } from "@sharedV11/classes/tipoCodigoPromociones/clientes_select";
import {
  tipoCodigoPromociones,
  tipoEntradaPlantilla,
  tipoPromoEntradaPlantilla,
} from "@sharedV11/classes/tipoCodigoPromociones/tipoCodigoPromociones";
import { TableInteractiveComponent } from "@sharedV11/components/tables/table-interactive/table-interactive.component";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { EditTipoCodigoPromocionesService } from "@sharedV11/services/components/edit-tipo-codigo-promociones/edit-tipo-codigo-promociones.service";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-edit-tipo-codigo-promociones",
  templateUrl: "./edit-tipo-codigo-promociones.component.html",
  styleUrls: ["./edit-tipo-codigo-promociones.component.scss"],
})
export class EditTipoCodigoPromocionesComponent implements OnInit {
  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  @ViewChild("ti_tiposEntrada") ti_tiposEntrada: TableInteractiveComponent;
  @ViewChild("ti_tiposPromociones")
  ti_tiposPromociones: TableInteractiveComponent;

  ///clientesASs: any;
  listaClientes: clientes_select[] = [];
  //////
  tiposEntrada: any;
  translation: any;
  elementosBorrados: string[] = [];
  tiposBonoEntrada: tipoEntradaPlantilla[];
  promocionEntradaFormGroup: FormArray = this.formBuilder.array([]);
  columnsToDisplayTarifaEntrada: string[];

  //editorRegistroForm: FormGroup;
  itemsTipoCodigoPromociones: tipoCodigoPromociones;

  dataSourceTarifaPromocionEntrada: MatTableDataSource<tipoEntradaPlantilla>;
  ///////
  tiposBonoEntrada1: tipoPromoEntradaPlantilla[];
  tiposEntrada1: any;
  elementosBorrados1: string[] = [];
  promocionEntradaFormGroup1: FormArray = this.formBuilder.array([]);
  columnsToDisplayTarifaEntrada1: string[];

  //editorRegistroForm1: FormGroup;

  dataSourceTarifaPromocionEntrada1: MatTableDataSource<tipoEntradaPlantilla>;
  ///////
  editRegistroForm: FormGroup;

  tablavalida = true;
  tablavalida1 = true;

  tipoCodigoPromociones: tipoCodigoPromociones;
  clientesloaded: boolean = false;
  listaCanales: any;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  centrosasociados: string[] = [];
  tipoCentros: any[] = [];
  CentrosAPI: any;
  format: {
    add: any;
    remove: any;
    all: any;
    none: any;
    direction: any;
    draggable: boolean;
    locale: string;
  };
  //centros

  constructor(
    private editTipoCodigoPromocionesService: EditTipoCodigoPromocionesService,
    public servicioAPI: ConectarApiService,
    private fb: FormBuilder,
    private translator: Translator,
    private cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    private snackvar_service: SnackbarInfoService,
    private httpErrorService: HttpErrorsService,
    private formBuilder: FormBuilder
  ) {}

  getNombreGrupoById(id: string) {
    // TODO: falta acceso a la tabla tbGrupoTipoBono.PkId
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    //this.cargarDatos();

    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode);

    this.translation = this.translator.GetTranslations();
    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };

    this.columnsToDisplayTarifaEntrada = [
      "Acciones",
      "NombreTipoEntrada",
      "iNumEntradas",
    ];

    this.columnsToDisplayTarifaEntrada1 = [
      "Acciones",
      "NombreTipoEntrada",
      "iNumEntradas",
    ];
    /*  this.tiposEntrada = {"DatosResult":{"tipoCodigoPromo":[{
      pkId: "1",
            Nombre: "ejemplo",
            chNombre: "ejemplo1",
            chDescripcion: "Descripción...",
            Descripcion: "Descripción...",
            bDeBaja: 0,
            iCodigoCliente: 0
    }]}}; */
    this.cargarDatos();
  }

  cargarDatos() {
    //Cargamos Datos

    let filters: Filter[] = [];
    this.servicioAPI.traerDatosClientes().subscribe(
      (data) => {
        console.log('lista clientes::', data.DatosListas)
        this.listaClientes = data.DatosListas;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.servicioAPI.ObtenerCustomanCanales().then(
      (data) => {
        this.listaCanales = data.DatosResult.Lista;
        this.listaCanales.forEach((element) => {
          element.canalsubcanal = element.Canal + " - " + element.SubCanal;
        });
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    //centros en cargar datos
    this.servicioAPI.getCentros().subscribe(
      (data) => {
        this.CentrosAPI = data;
        this.tipoCentros = [];
        if (this.CentrosAPI.DatosResult != null) {
          if (this.CentrosAPI.DatosResult.ListaCentros != null) {
            this.tipoCentros = this.CentrosAPI.DatosResult.ListaCentros;
            this.centrosSinAsignar = this.filtrarTiposCentros();
            this.centrosAsignados = this.daTiposCentros();
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
    //centros
  }

  //centros
  filtrarTiposCentros() {
    // return this.tipoCentros.filter(x => x.Activo == "True");
    let respuesta = [];
    let Centros = this.tipoCentros.filter((x) => x.Activo == "True");
    let tmpCentrosAsociados = this.centrosasociados;
    if (Centros != null && Centros.length > 0) {
      respuesta = Centros.filter(function (item) {
        return (
          tmpCentrosAsociados.findIndex(function (itemSub) {
            return itemSub == item.pkId;
          }) === -1
        );
      });
    } else {
      respuesta = this.tipoCentros.filter((x) => x.Activo == "True");
    }
    return respuesta;
  }

  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2
      ? o1.id === o2.id || o1 === o2.id || o1.id === o2
      : o1 === o2;
  }
  asignarTipoCentro(item, evento) {
    this.centrosasociados.push(item.pkId);
  }
  desasignarTipoCentro(item, evento) {
    let arrtemp = [];
    arrtemp = this.centrosasociados.filter(function (x) {
      return x != item.pkId;
    });
    this.centrosasociados = arrtemp;
  }

  deasignarTodosCentros() {
    this.centrosasociados = [];
  }
  asignarTodosCentros() {
    this.centrosasociados = this.tipoCentros
      .filter((x) => x.Activo == "True")
      .map(function (x) {
        return x.pkId;
      });
  }

  obtenercentrosAsociadosconOrden(): string {
    let respuesta: string = "";
    let elemento: string;
    let count = 0;
    if (this.centrosAsignados) {
      this.centrosAsignados.forEach((centro) => {
        //let orden = this.getOrdenRecinto(actividad);
        elemento = centro.pkId + ",";
        // elemento = element + ":" + (this.ordenRecintosAsociados[count] == null ? '0' : this.ordenRecintosAsociados[count]) + ",";
        respuesta = respuesta + elemento;
        count++;
      });
    }
    return respuesta.substring(0, respuesta.length - 1);
  }

  daTiposCentros(): any[] {
    let respuesta = [];
    if (this.tipoCentros != null) {
      if (this.tipoCodigoPromociones.CentrosIds) {
        let arrayCentrosString =
          this.tipoCodigoPromociones.CentrosIds.split(",");
        let arrayCentros = [];

        let allCentros = this.tipoCentros.filter((x) => x.Activo == "True");

        let array = arrayCentrosString.map((centropkId) => {
          let act = allCentros.find((find) => find.pkId == centropkId);
          respuesta.push(act);
        });
      }
    }

    return respuesta;
  }
  //centros

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  initializeFormWithoutData() {
    this.editRegistroForm = this.fb.group({
      pkId: [""],
      Nombre: ["", Validators.required],
      Descripcion: [""],
      CodigoCliente: ["", Validators.required],
      CanalNombre: [""],
      DeBaja: 0,
      CanalId: 0,
    });
  }
  manageForm(pkId, mode) {
    if (mode == "I") {
      this.tipoCodigoPromociones = this.editRegistroForm.value;
      this.loading = false;
      this.valuedChanged();
    }
    if (pkId != null) {
      this.findTipoCodigoPromocional(pkId);
    }
  }

  findTipoCodigoPromocional(pkId) {
    this.editTipoCodigoPromocionesService
      .getTiposCodigoPromocionalByPkId(pkId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => {
          // let Categoria:Categoria =  CategoriaApi["DatosResult"]["customanUsuarios"][0];
          let tipocodpromo: tipoCodigoPromociones =
            data["DatosResult"]["ListaCodigoPromocional"][0];
          if (tipocodpromo != null) {
            this.changeValueForm(tipocodpromo);
            this.tipoCodigoPromociones = this.editRegistroForm.value;
            this.tipoCodigoPromociones.CentrosIds = tipocodpromo.CentrosIds;
            this.centrosAsignados = this.daTiposCentros();
            this.valuedChanged();
            this.loading = false;
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  changeValueForm(tipoCodigoPromociones: tipoCodigoPromociones) {
    this.editRegistroForm.get("pkId").setValue(tipoCodigoPromociones.pkId);

    this.editRegistroForm
      .get("Nombre")
      .setValue(
        tipoCodigoPromociones.chNombre == undefined
          ? tipoCodigoPromociones.Nombre
          : tipoCodigoPromociones.chNombre
      );
    this.editRegistroForm
      .get("Descripcion")
      .setValue(
        tipoCodigoPromociones.chDescripcion == undefined
          ? tipoCodigoPromociones.Descripcion
          : tipoCodigoPromociones.chDescripcion
      );
    this.editRegistroForm
      .get("CodigoCliente")
      .setValue(tipoCodigoPromociones.CodigoCliente ?? '');


    this.editRegistroForm
      .get("DeBaja")
      .setValue(tipoCodigoPromociones.DeBaja == "1" ? true : false);
    
    this.editRegistroForm
      .get("CanalId")
      .setValue(tipoCodigoPromociones.CanalId);
  }

  valuedChanged() {
    this.editRegistroForm.valueChanges.subscribe((value) => {
      if (this.tipoCodigoPromociones != null) {
        this.panelService.setDiscardChanges(false);
        if (
          JSON.stringify(value) === JSON.stringify(this.tipoCodigoPromociones)
        ) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }
  submit(cerrarpanel: boolean = true) {
    if (
      this.ti_tiposEntrada.dataSource.data.length > 0 ||
      this.ti_tiposPromociones.dataSource.data.length > 0
    ) {
      this.editRegistroForm.markAllAsTouched();
      let tipoCodigoPromociones: tipoCodigoPromociones =
        this.editRegistroForm.value;
      tipoCodigoPromociones["TipoCodigoPromocionalEntradas"] =
        this.ti_tiposEntrada.dataSource.data;
      tipoCodigoPromociones["TipoCodigoPromocionalTiposBono"] =
        this.ti_tiposPromociones.dataSource.data;
      if (this.editRegistroForm.valid) {
        this.manageApi(tipoCodigoPromociones, this.mode, cerrarpanel);
      } else {
        this.snackvar_service.openSnackBar("Faltan datos", "", "red-snackbar");
      }
    } else {
      this.snackvar_service.openSnackBar(
        "Es necesario añadir un producto",
        "",
        "red-snackbar"
      );
    }
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }

  save(cerrarpanel?) {
    this.loading = false;
    this.panelService.setDiscardChanges(true);

    this.panelService.setSavePanel(cerrarpanel);
  }
  discard() {
    this.changeValueForm(this.tipoCodigoPromociones);
  }
  manageApi(tipoCodigoPromociones: tipoCodigoPromociones, mode, cerrarpanel?) {
    this.loading = true;
    //TipoCodigoPromocionalEntradas
    //TipoCodigoPromocionalTiposBono
    if (tipoCodigoPromociones.TipoCodigoPromocionalEntradas)
      tipoCodigoPromociones.TipoCodigoPromocionalEntradas.forEach((e) => {
        e.DeBaja = e.DeBaja == true ? "1" : "0";
      });

    if (tipoCodigoPromociones.TipoCodigoPromocionalTiposBono)
      tipoCodigoPromociones.TipoCodigoPromocionalTiposBono.forEach((e) => {
        e.DeBaja = e.DeBaja == true ? "1" : "0";
      });

    tipoCodigoPromociones.CentrosIds = this.obtenercentrosAsociadosconOrden();

    if (this.editRegistroForm.controls["DeBaja"].value == true)
      tipoCodigoPromociones.DeBaja = "1";
    else tipoCodigoPromociones.DeBaja = "0";
    switch (mode) {
      case "I":
        this.InsTipoCodigoPromo(tipoCodigoPromociones, cerrarpanel);
        break;
      case "U":
        //update
        this.UpdTipoCodigoPromo(tipoCodigoPromociones, cerrarpanel);

        break;
      case "D":
        //duplicate
        tipoCodigoPromociones.pkId = "0";
        this.InsTipoCodigoPromo(tipoCodigoPromociones, cerrarpanel);

        break;

      default:
        break;
    }
  }

  UpdTipoCodigoPromo(Categoria, cerrarpanel?) {
    this.editTipoCodigoPromocionesService
      .updTipoCodigoPromo(Categoria, "U")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          if (!response.DatosResult)
            this.snackvar_service.openSnackBar(
              response.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
          this.nextWindow(response, cerrarpanel);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  InsTipoCodigoPromo(Categoria, cerrarpanel?) {
    this.editTipoCodigoPromocionesService
      .insTipoCodigoPromo(Categoria, "I")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          if (!cerrarpanel) {
            //this.findTipoCodigoPromocional(response.DatosResult.id);
            this.mode = "U";
            this.pkId = response.DatosResult.id;
            this.cdRef.detectChanges();
            this.ti_tiposEntrada.reloadTable();
            this.ti_tiposPromociones.reloadTable();
            //this.entryType.CentrosIds = this.obtenercentrosAsociadosconOrden()
            this.initializeTranslate();
            this.initializeFormWithoutData();
            this.manageForm(this.pkId, this.mode);
            this.translation = this.translator.GetTranslations();
            this.columnsToDisplayTarifaEntrada = [
              "Acciones",
              "NombreTipoEntrada",
              "iNumEntradas",
            ];
            this.columnsToDisplayTarifaEntrada1 = [
              "Acciones",
              "NombreTipoEntrada",
              "iNumEntradas",
            ];
            this.cargarDatos();
          } else {
            if (!response.DatosResult)
              this.snackvar_service.openSnackBar(
                response.Mensajes[0].DescripcionMensaje,
                "",
                "red-snackbar"
              );
            this.nextWindow(response, cerrarpanel);
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  nextWindow(response: any, cerrarpanel?) {
    const err = this.existErrors();
    if (!this.existErrors()) {
      this.save(cerrarpanel);
      if (cerrarpanel && cerrarpanel == true) this.destroy();
      if (response.Mensajes.length > 0) {
        response.Mensajes.forEach((element) => {
          this.snackvar_service.openSnackBar(
            element.DescripcionMensaje,
            "",
            "red-snackbar",
            element.CodigoMensaje
          );
        });
      } else {
        this.snackvar_service.openSnackBar(
          this.translation["MensajeSuccessSnackBar"],
          "",
          "green-snackbar"
        );
      }
    }
  }
  existErrors() {
    return this.editRegistroForm.get("pkId").errors ||
      this.editRegistroForm.get("Nombre").errors ||
      this.editRegistroForm.get("Descripcion").errors ||
      this.editRegistroForm.get("CodigoCliente").errors
      ? true
      : false;
  }
  getErrorMessage(field: string) {
    let fc_pv = this.editRegistroForm.get(field);
    if (fc_pv) {
      if (fc_pv.errors) {
        if (fc_pv.errors.required) {
          return this.translation["CampoRequerido"];
        } else if (field === "Empleado") {
          if (fc_pv.hasError("pattern")) {
            return this.translation["SoloNumeros"];
          }
        } else if (field === "Nombre") {
          if (fc_pv.hasError("pattern")) {
            return this.translation["SoloLetras"];
          }
        }
      }
    }

    return "Error";
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.editRegistroForm.controls;
    return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
  }

  /* 
  get elpkId() {
    return this.editRegistroForm.controls["pkId"].value;
  }
 */
  closeInputFilter(enventValues) {
    this.editRegistroForm.get(enventValues.name).setValue(enventValues.value);
  }
}
