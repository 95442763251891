import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { Paginator } from "@sharedV11/classes/paginator/paginator";
import { DaysWeek, GrupoTarifaResponse, IGrupoTarifa, IUGrupoTarifaAPI, Mode } from "@sharedV11/classes/tarifas/grupostarifas";
import { Tiporeserva } from "@sharedV11/classes/tarifas/tiposreservas";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { GruposTarifasService } from "@sharedV11/services/components/grupos-tarifas/grupos-tarifas.service";
import { EditTiposReservasService } from "@sharedV11/services/components/tiposreservas/edit-tiposreservas";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { isEqual } from "@sharedV11/utils/lodash";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";


@Component({
	selector: "app-edit-grupos-tarifas",
	templateUrl: "./edit-grupos-tarifas.component.html",
	styleUrls: ["./edit-grupos-tarifas.component.scss"],
})
export class EditGruposTarifasComponent implements OnInit {
	loading = true;
	private ngUnsubscribe = new Subject();

	@Input() pkId: string;
	@Input() mode: Mode;
	@Input() modificar: boolean;

	translation: any;

	editRegistroForm: FormGroup;

	GrupoTarifa: IGrupoTarifa;

	//tarifas dual list
	tarifasAsignadas = [];
	tarifasSinAsignar = [];

	// Clientes dual list
	clientesAsignados = [];
	clientesSinAsignar = [];

	//selects
	PVKSelected: boolean;
	IntSelected: boolean;
	AdminSelected: boolean;

	daysWeek: DaysWeek = {
		Lunes: 0,
		Martes: 0,
		Miercoles: 0,
		Jueves: 0,
		Viernes: 0,
		Sabado: 0,
		Domingo: 0
	}

	//Tarifas paginator
	paginator: Paginator = {
		page: 1,
		pagelements: 10,
		orderby: "pkId",
		ordertype: "ASC",
	};

	//centros
	centrosSinAsignar = [];
	centrosAsignados = [];
	tipoCentros: any[] = [];


	//centros
	format = {
		add: "Añadir",
		remove: "Borrar",
		all: "Todos",
		none: "Ninguno",
		direction: DualListComponent.LTR,
		draggable: true,
		locale: "es",
	};

	constructor(
		private panelService: PanelService,
		private fb: FormBuilder,
		private translator: Translator,
		private httpErrorService: HttpErrorsService,
		private snackvar_service: SnackbarInfoService,
		private editTiposReservasService: EditTiposReservasService,
		private gsCentros: GlobalServicCentros,
		private servicioAPI: ConectarApiService,
		private grupoTarifaService: GruposTarifasService,
	) {}

	async ngOnInit(){
		try {
			await this.grupoTarifaService.cargarDatos(),
			await this.loadCentros(),

			this.initializeTranslate();
			this.initializeFormWithoutData();
			this.manageForm(this.pkId, this.mode);
		} catch (err) {
			console.error(err);
		}
	}

	getTotalTarifas() {
		return this.grupoTarifaService.totalTarifasRows;
	}

	async loadCentros() {
		await this.gsCentros.getCentros();
	}


	initializeTranslate() {
		this.translation = this.translator.GetTranslations();
    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };
	}

	initializeFormWithoutData() {
		this.editRegistroForm = this.fb.group({
			pkId: [""],
			Nombre: ["", Validators.required],
			Descripcion: [""],
      TipoDescuento: ["PR", Validators.required],
      ImporteDescuento: [0, Validators.required],
			tipoControlPlataforma: ["", [Validators.required]],
      diasSemana: [[]],
			Habilitado: [false, Validators.required],
		});
	}

	manageForm(pkId, mode) {
		if (mode == "I") {
			this.GrupoTarifa = this.editRegistroForm.value;

			//tarifas
			this.tarifasSinAsignar = this.grupoTarifaService.filtrarTarifas();

			this.tarifasAsignadas = [];

			//centros
			this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
			this.centrosAsignados = this.gsCentros.daTiposCentros(this.GrupoTarifa);

			//clientes
			this.clientesSinAsignar = this.grupoTarifaService.filtrarClientes();

			this.clientesAsignados = [];

			this.loading = false;
			this.valuedChanged();
		}
		if (pkId != null) {
			this.findGrupoTarifa(pkId);
		}
	}

	findGrupoTarifa(pkId) {
		this.grupoTarifaService
			.findGrupoTarifaByPkId(pkId)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(GrupoTarifaApi) => {
					let grupoTarifa: IGrupoTarifa = GrupoTarifaApi["DatosResult"]["Lista"][0];

					if (grupoTarifa != null) {
						this.changeValueForm(grupoTarifa);
						this.GrupoTarifa = this.editRegistroForm.value;
						this.GrupoTarifa.CentrosIds = grupoTarifa.CentrosIds;
						this.GrupoTarifa.Clientes = grupoTarifa.Clientes;
						this.GrupoTarifa.Tarifas = grupoTarifa.Tarifas;

						this.valuedChanged();

						//tarifas
						const tarifasAsignadasIds = grupoTarifa.Tarifas ? grupoTarifa.Tarifas.split(",") : [];

						this.tarifasSinAsignar = this.grupoTarifaService.filtrarTarifas();

						this.tarifasAsignadas = this.grupoTarifaService.getArrTarifasAsignadas(grupoTarifa.Tarifas);

						//centros
						this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
						this.centrosAsignados = this.gsCentros.daTiposCentros(this.GrupoTarifa);

						//clientes
						const clientesAsignadosIds = grupoTarifa.Clientes ? grupoTarifa.Clientes.split(",") : [];
						this.clientesSinAsignar = this.grupoTarifaService.filtrarClientes();

						this.clientesAsignados = this.grupoTarifaService.getArrClientesAsignados(grupoTarifa.Clientes);
						this.loading = false;
					}
				},
				(error) => {
					this.httpErrorService.identificarErrores(error);
				}
			);
	}

	actualizarPlataformasSeleccionadas() {
		// 1 - Punto Venta Kore
		// 2 - Internet
		// 3 - Administración

		this.PVKSelected = this.editRegistroForm.controls[
			"tipoControlPlataforma"
		].value.includes("1")
			? true
			: false;

		this.IntSelected = this.editRegistroForm.controls[
			"tipoControlPlataforma"
		].value.includes("2")
			? true
			: false;

		this.AdminSelected = this.editRegistroForm.controls[
			"tipoControlPlataforma"
		].value.includes("3")
			? true
			: false;
	}

	actualizarDiasSeleccionados() {
		this.daysWeek.Lunes = this.editRegistroForm.controls[
			"diasSemana"
		].value.includes("1")
			? 1
			: 0;
		this.daysWeek.Martes = this.editRegistroForm.controls[
			"diasSemana"
		].value.includes("2")
			? 1
			: 0;
		this.daysWeek.Miercoles = this.editRegistroForm.controls[
			"diasSemana"
		].value.includes("3")
			? 1
			: 0;
		this.daysWeek.Jueves = this.editRegistroForm.controls[
			"diasSemana"
		].value.includes("4")
			? 1
			: 0;
		this.daysWeek.Viernes = this.editRegistroForm.controls[
			"diasSemana"
		].value.includes("5")
			? 1
			: 0;
		this.daysWeek.Sabado = this.editRegistroForm.controls[
			"diasSemana"
		].value.includes("6")
			? 1
			: 0;
		this.daysWeek.Domingo = this.editRegistroForm.controls[
			"diasSemana"
		].value.includes("0")
			? 1
			: 0;
	}

	changeValueForm(GrupoTarifa: IGrupoTarifa) {

		const arrDaysWeek = [
			{
				name: 'Lunes',
				value: '1'
			},
			{
				name: 'Martes',
				value: '2'
			},
			{
				name: 'Miercoles',
				value: '3'
			},
			{
				name: 'Jueves',
				value: '4'
			},

			{
				name: 'Viernes',
				value: '5'
			},
			{
				name: 'Sabado',
				value: '6'
			},
			{
				name: 'Domingo',
				value: '0'
			},
		]

		this.editRegistroForm.get("pkId").setValue(GrupoTarifa.pkId);
		this.editRegistroForm.get("Nombre").setValue(GrupoTarifa.Nombre);
		this.editRegistroForm.get("Descripcion").setValue(GrupoTarifa.Descripcion);
		this.editRegistroForm
			.get("Habilitado")
			.setValue(Number(GrupoTarifa.Habilitado) === 1 ? true : false);

		const platformsSelected = [];

		if (Number(GrupoTarifa.Taquilla) === 1 ) {
			platformsSelected.push("1");
		}

		if (Number(GrupoTarifa.Internet) === 1 ) {
			platformsSelected.push("2");
		}

		if (Number(GrupoTarifa.IntAdmon) === 1 ) {
			platformsSelected.push("3");
		}


		this.editRegistroForm.get("TipoDescuento").setValue(GrupoTarifa.TipoDescuento)
		this.editRegistroForm.get("ImporteDescuento").setValue(GrupoTarifa.Descuento);
		this.editRegistroForm.get("tipoControlPlataforma").setValue(platformsSelected);

		this.actualizarPlataformasSeleccionadas();

		//Fill days

		const daysSelected = [];

		for (const dayWeek of arrDaysWeek ) {
			if (Number(GrupoTarifa[dayWeek.name]) === 1) {
				daysSelected.push(dayWeek.value)
			} 
		}

		this.editRegistroForm.get('diasSemana').setValue(daysSelected);

		this.actualizarDiasSeleccionados();
	}

	valuedChanged() {
		this.editRegistroForm.valueChanges.subscribe((value) => {
			if (this.GrupoTarifa != null) {
				console.log('value changes::', value)
				console.log('value grupo tarifa::', this.GrupoTarifa)

				this.panelService.setDiscardChanges(false);

				const keysForm = Object.keys(value);

				const valuesDismatch = [];

				for (const keyForm of keysForm) {
					const valueForm = value[keyForm];
					const valueGrupoTarifa = this.GrupoTarifa[keyForm];

					if (
						!isEqual(valueForm, valueGrupoTarifa)
					) {
						valuesDismatch.push(1);
					}
				}

				if (valuesDismatch.length === 0) {
					this.panelService.setDiscardChanges(true);
				}
			}
		});
	}

	discard() {
		this.changeValueForm(this.GrupoTarifa);
	}

	manageApi(GrupoTarifa: IGrupoTarifa, mode: Mode) {
		this.loading = true;

		GrupoTarifa.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
			this.centrosAsignados
		);

		GrupoTarifa.Clientes = this.grupoTarifaService.getClientesAsignadosJoin(
			this.clientesAsignados
		);

		GrupoTarifa.Tarifas = this.grupoTarifaService.getTarifasAsignadasJoin(
			this.tarifasAsignadas
		);

		switch (mode) {
			case "I":
				//insert
				GrupoTarifa.pkId = "0";
				this.InsUpGrupoTarifa(GrupoTarifa, "I");
				break;
			case "U":
				//update
				this.InsUpGrupoTarifa(GrupoTarifa, "U");

				break;
			case "D":
				//duplicate
				GrupoTarifa.pkId = "0";
				this.InsUpGrupoTarifa(GrupoTarifa, "I");

				break;

			default:
				break;
		}
	}

	DelTiporeserva(TPV) {
		/*   this.editTiposReservasService.updCategoria(TPV, "U").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     }); */
	}

	InsUpGrupoTarifa(grupoTarifa: IGrupoTarifa, mode) {
		this.grupoTarifaService
			.insUpGrupoTarifa(grupoTarifa, mode)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(response) => {
					this.nextWindow(response);
				},
				(error) => {
					this.httpErrorService.identificarErrores(error);
				}
			);
	}

	destroy() {
		this.panelService.setDiscardChanges(true);
		this.panelService.setClosePanel(null);
	}

	submit() {
		this.editRegistroForm.markAllAsTouched();

		//Si no se a seleccionado ningun dia de la semana se seleccionan todos por defecto
		if (this.editRegistroForm.get("diasSemana").value.length === 0) {
			this.editRegistroForm.get("diasSemana").setValue(["0", "1", "2", "3", "4", "5", "6"]);
		}

		let grupoTarifa: IGrupoTarifa = {
			pkId: this.editRegistroForm.get("pkId").value,
			Nombre: this.editRegistroForm.get("Nombre").value,
			Descripcion: this.editRegistroForm.get("Descripcion").value,
			TipoDescuento: this.editRegistroForm.get("TipoDescuento").value,
			Descuento: this.editRegistroForm.get("ImporteDescuento").value,
			Habilitado: this.editRegistroForm.get("Habilitado").value === true ? 1 : 0,
			...this.daysWeek,
			Taquilla: this.PVKSelected === true ? 1 : 0,
			TaqAutomatica: 0,
			Internet: this.IntSelected === true ? 1 : 0,
			IntAdmon: this.IntSelected === true ? 1 : 0,
		}
		if (this.editRegistroForm.valid) {
			this.manageApi(grupoTarifa, this.mode);
		}
	}

	getErrorMessage(field: string) {
		let fc_pv = this.editRegistroForm.get(field);
		if (fc_pv) {
			if (fc_pv.errors) {
				if (fc_pv.errors.required) {
					return this.translation["CampoRequerido"];
				} else if (field === "Empleado") {
					if (fc_pv.hasError("pattern")) {
						return this.translation["SoloNumeros"];
					}
				} else if (field === "Nombre") {
					if (fc_pv.hasError("pattern")) {
						return this.translation["SoloLetras"];
					}
				}
			}
		}

		return "Error";
	}

	nextWindow(response: any) {
		this.save();
		this.destroy();
		if (response.Mensajes.length > 0) {
			console.table(response.Mensajes);
			response.Mensajes.forEach((element) => {
				this.snackvar_service.openSnackBar(
					element.DescripcionMensaje,
					"",
					"red-snackbar",
					element.CodigoMensaje,
					4000
				);
			});
		} else {
			this.snackvar_service.openSnackBar(
				this.translation["MensajeSuccessSnackBar"],
				"",
				"green-snackbar"
			);
		}
	}

	save() {
		this.loading = false;
		this.panelService.setSavePanel(null);
	}

	validateFields(field: string) {
		return this.isValidAnyField(field);
	}

	isValidAnyField(field: string) {
		let fc_pv = this.editRegistroForm.controls;
		return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
	}
}
